import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ResultsTable = ({ data }) => {
  if (!data) {
    return null; // or display a loading/error message
  }

  const setKeys = Object.keys(data);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>Set</TableCell>
            <TableCell>Dimensions</TableCell>
            <TableCell>A (Min)</TableCell>
            <TableCell>A (Max)</TableCell>
            <TableCell>A (Avg)</TableCell>
            <TableCell>A1 (Min)</TableCell>
            <TableCell>A1 (Max)</TableCell>
            <TableCell>A1 (Avg)</TableCell>
            <TableCell>A2 (Min)</TableCell>
            <TableCell>A2 (Max)</TableCell>
            <TableCell>A2 (Avg)</TableCell>
            <TableCell>A3 (Min)</TableCell>
            <TableCell>A3 (Max)</TableCell>
            <TableCell>A3 (Avg)</TableCell>
            <TableCell>B (Min)</TableCell>
            <TableCell>B (Max)</TableCell>
            <TableCell>B (Avg)</TableCell>
            <TableCell>C (Min)</TableCell>
            <TableCell>C (Max)</TableCell>
            <TableCell>C (Avg)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {setKeys.map((setName) => {
            const set = data[setName];
            return (
              <TableRow key={setName}>
                <TableCell>{setName}</TableCell>
                <TableCell>{set.dimensions}</TableCell>
                <TableCell>{set.A.min}</TableCell>
                <TableCell>{set.A.max}</TableCell>
                <TableCell>{set.A.average.toFixed(2)}</TableCell>
                <TableCell>{set.A1.min}</TableCell>
                <TableCell>{set.A1.max}</TableCell>
                <TableCell>{set.A1.average.toFixed(2)}</TableCell>
                <TableCell>{set.A2.min}</TableCell>
                <TableCell>{set.A2.max}</TableCell>
                <TableCell>{set.A2.average.toFixed(2)}</TableCell>
                <TableCell>{set.A3.min}</TableCell>
                <TableCell>{set.A3.max}</TableCell>
                <TableCell>{set.A3.average.toFixed(2)}</TableCell>
                <TableCell>{set.B.min}</TableCell>
                <TableCell>{set.B.max}</TableCell>
                <TableCell>{set.B.average.toFixed(2)}</TableCell>
                <TableCell>{set.C.min.toFixed(2)}</TableCell>
                <TableCell>{set.C.max.toFixed(2)}</TableCell>
                <TableCell>{set.C.average.toFixed(2)}</TableCell>
              
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;
