import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../Components/PlayButton/PlayButton';
import { Button, Typography } from '@mui/material';
import './LandingPage.css'

const LandingPage = () => {
  return (
    <div className="landing-page">

      {/* App description */}
      <div>
        <Typography variant="h2" style={{ color: '#003366', paddingBottom: '5px' }}>
          Welcome to Artistic Uncertainty!
        </Typography>
        <Typography variant="h6" style={{ color: '#003366', paddingLeft: '50px', paddingRight: '50px' }}>
          Artistic Uncertainty is an interactive art generator that allows you to create beautiful patterns using a blend of colors. Watch the tutorial video below then click GET STARTED!
        </Typography>
      </div>

<PlayButton />
      
      {/* Button to navigate to the form */}
      <div className="button-container">
        <Link to="/form">
          <Button variant="contained" color="primary" sx={{
            transition: 'transform .3s ease',
            '&:hover': {
              transform: 'scale(1.2)',
              backgroundColor: '#2ecc71',
            }
          }}>
            Get Started
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
