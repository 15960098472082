import { AppBar, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Website_Logo.png'

const Navbar = () => {

    return (
        <AppBar sx={{
            position: 'fixed', 
            background: 'linear-gradient(to right, #3498db, #2ecc71)', // Change colors as needed
        }}>
            <Toolbar>
                <Typography>
                <img src={logo} alt="Logo" style={{
                    width: '70px',
                }}/>
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Artistic Uncertainty
                </Typography>
                <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex' }}>
                    <li style={{ margin: '0 10px' }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                            Home
                        </Link>
                    </li>
                    <li style={{ margin: '0 10px' }}>
                        <Link to="/form" style={{ textDecoration: 'none', color: 'white' }}>
                            Single Grid Form
                        </Link>
                    </li>
                    <li style={{ margin: '0 10px' }}>
                        <Link to="/simulationLandingPage" style={{ textDecoration: 'none', color: 'white' }}>
                            Experiment Home
                        </Link>
                    </li>
                    <li style={{ margin: '0 10px' }}>
                        <Link to="/simulationForm" style={{ textDecoration: 'none', color: 'white' }}>
                            Experiment Form
                        </Link>
                    </li>
                    
                </ul>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar