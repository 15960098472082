import React, { useEffect, useState } from 'react';
import Grid from '../../Components/Grid.jsx';
import './SimulationForm.css';
import ColorPickers from '../../Components/ColorPickers/ColorPickers.jsx';
import Divider from '../../Components/Divider.jsx';
import { blendColors } from '../../assets/blendColors.js';
import { Typography, TextField, Button, Slider, Grid as MuiGrid, Checkbox, FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import SimulationResultsTable from '../../Components/SimulationResults/SimulationResultsTable.jsx';


const SimulationForm = () => {
    const initialColors = ['#ff0000', '#00ff00', '#0000ff']; // Default colors: red, green, blue
    const [colors, setColors] = useState(initialColors);
    const [renderGrid, setRenderGrid] = useState(false)
    const [speed, setSpeed] = useState(0)

    const [paintAmount, setPaintAmount] = useState('');
    const [enableSound, setEnableSound] = useState(false); // New state for sound option

    const [squarePaintedTwiceCheck, setSquarePaintedTwiceCheck] = useState(false)


    // const [xAxisCheck, setXAxisCheck] = useState(false);
    const [repetitionsCheck, setRepetitionsCheck] = useState(false);
    const [dimensionCheck, setDimensionCheck] = useState(false);

    const [repetitionList, setRepetitionList] = useState('')
    const [dimensionList, setDimensionList] = useState('')

    const [repetitionConstant, setRepetitionConstant] = useState(0)
    const [dimensionConstant, setDimensionConstant] = useState(5)

    const [repArray, setRepArray] = useState([])
    const [dimArray, setDimArray] = useState([])

    const [masterGridData, setMasterGridData] = useState({})
    const [setStatistics, setSetStatistics] = useState({});

    const [displayStats, setDisplayStats] = useState(false)

    const handleStatDisplay = () => {
        handleClose()
        setOpenStats(true)
    }

    const handleStatsClose = () => {
        setOpenStats(false)
    }

    const [open, setOpen] = useState(false);
    const [openStats, setOpenStats] = useState(false)



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setRenderGrid(false)
    };

    const handleGridUpdate = (gridName, set, data) => {
        // Update the grid data in the state
        setMasterGridData((prevGridData) => ({
            ...prevGridData,
            [set]: {
                ...prevGridData[set],
                [gridName]: data,
            }
        }));

        console.log(masterGridData)
    };

    const [error, setError] = useState('');
    const [validList, setValidList] = useState(false)


    const addColor = (color) => {
        setColors([...colors, color])
    }

    const handleColorChange = (newColor, index) => {
        const updatedColors = [...colors];
        updatedColors[index] = newColor.hex;
        setColors(updatedColors);
    };

    const handleSubmit = (e) => {
        setTimeout(setRenderGrid(false), 0)

        e.preventDefault();

        // console.log("Submitted")

        setRenderGrid(true)
        handleOpen()

        // const xDimension = parseInt(formData.xDimension);
        // const yDimension = parseInt(formData.yDimension);

        // console.log(xDimension)
        // console.log(yDimension)

        // if (!isNaN(xDimension) && xDimension > 0 && !isNaN(yDimension) && yDimension > 0) {
        //     // Valid dimensions, create the grid
        //     setRenderGrid(true)
        //     console.log("Started paint at: " + Date());
        // } else {
        //     console.log("No values")
        //     // Invalid dimensions, handle the error (e.g., show an error message)
        // }
        // Handle form submission logic here
    };

    const handleReset = () => {
        setRenderGrid(false)
    }

    useEffect(() => {
        const calculateSetStatistics = () => {
            const allSetsData = {};

            Object.keys(masterGridData).forEach((setName) => {
                const setGrids = masterGridData[setName];

                const setStats = {
                    A: [],
                    A1: [],
                    A2: [],
                    A3: [],
                    B: [],
                    C: [],
                };

                const setDimension = setGrids.grid0.dimension || 'N/A';
                // console.log(setGrids.grid0.dimension)

                Object.keys(setGrids).forEach((gridKey) => {
                    const grid = setGrids[gridKey];

                    setStats.A.push(grid.total);
                    setStats.A1.push(grid.color1);
                    setStats.A2.push(grid.color2);
                    setStats.A3.push(grid.color3);
                    setStats.B.push(grid.maxSingleSquare);
                    setStats.C.push(grid.averagePaintDropsPerSquare);
                });

                // Calculate min, max, and average for each property
                const calculatePropertyStats = (property) => {
                    return {
                        min: Math.min(...setStats[property]),
                        max: Math.max(...setStats[property]),
                        average: setStats[property].reduce((acc, val) => acc + val, 0) / setStats[property].length,
                    };
                };

                // Store the results for each set in the state
                allSetsData[setName] = {
                    dimensions: setDimension,
                    A: calculatePropertyStats('A'),
                    A1: calculatePropertyStats('A1'),
                    A2: calculatePropertyStats('A2'),
                    A3: calculatePropertyStats('A3'),
                    B: calculatePropertyStats('B'),
                    C: calculatePropertyStats('C'),
                };
            });

            setSetStatistics(allSetsData);
        };

        calculateSetStatistics();

        console.log(setStatistics);

    }, [masterGridData]);

    // useEffect(() => {
    //     const calculateStatistics = () => {
    //         let total = 0;
    //         let amountOfGrids = 0;
    //         let A = 0;
    //         let A1 = 0;
    //         let A2 = 0;
    //         let A3 = 0;
    //         let B = 0;
    //         let C = 0;

    //         Object.keys(masterGridData).forEach((gridKey) => {
    //           const grid = masterGridData[gridKey];
    //           total += grid.total;
    //             amountOfGrids += 1
    //           if(grid.color1 > A1) A1 = grid.color1
    //           if(grid.color2 > A2) A2 = grid.color2
    //           if(grid.color3 > A3) A3 = grid.color3

    //           B = Math.max(B, grid.maxSingleSquare);
    //           C += grid.averagePaintDropsPerSquare;
    //         });

    //         C /= Object.keys(masterGridData).length; // Calculate the average for C

    //         A = total / amountOfGrids;
    //         // Store the results in the state
    //         setSimulationResults({ A, A1, A2, A3, B, C });
    //       };

    //         calculateStatistics();
    //         console.log(simulationResults)

    // }, [masterGridData])

    const handleDimensionChange = (event) => {
        setDimensionCheck(event.target.checked)
        // setXAxisCheck(false)
        setRepetitionsCheck(false)
        setDimensionList('')
        setRepetitionList('')
    }

    // const handleXAxisChange = (event) => {
    //     setDimensionCheck(false)
    //     // setXAxisCheck(event.target.checked)
    //     setRepetitionsCheck(false)
    // }

    const handleCheckboxChange = (event) => {
        setDimensionCheck(false)
        // setXAxisCheck(false)
        setRepetitionsCheck(event.target.checked)
        setDimensionList('')
        setRepetitionList('')
    }


    const handleDimensionList = (e) => {
        // console.log('Changed dims')
        setDimensionList(e.target.value)
        setValidList(false)
    }

    const handleRepetitionList = (e) => {
        // console.log('Changed reps')
        setRepetitionList(e.target.value)
        setValidList(false)
    }

    const handleRepetitionConstant = (e) => {
        setRepetitionConstant(e.target.value)
    }

    const handleDimensionConstant = (e) => {
        setDimensionConstant(e.target.value)
    }


    const handleErrorValidation = (repetitions, dimensions) => {

        // console.log(repetitions)
        // console.log(dimensions)

        const repetitionsArray = repetitions.split(',').map(Number);
        const dimensionsArray = dimensions.split(',').map(Number);

        // console.log(repetitionsArray.length)
        // console.log(dimensionsArray.length)

        if (repetitionsArray.some(isNaN) || dimensionsArray.some(isNaN) || repetitionsArray.some(num => num % 1 !== 0) || dimensionsArray.some(num => num % 1 !== 0)) {
            setError('List contains non integer values.');
            return
        } else {
            setError('');

            // Further processing or sending the data to the server can be done here
            // console.log();
        }

        if (repetitionsArray.length < 5 && dimensionsArray.length < 5) {
            setError('Not enough values. Enter at least 5.');
            return
        } else {
            setError('');

            // Further processing or sending the data to the server can be done here
            // console.log();
        }

        if (repetitionsArray.length > 10 || dimensionsArray.length > 10) {
            setError('Too many values. Maximum is 10.');
            return
        } else {
            setError('');

            // Further processing or sending the data to the server can be done here
            // console.log();
        }

        setValidList(true)
        setRepArray(repetitionsArray)
        setDimArray(dimensionsArray)

    }

    const handleSquarePaintedTwiceChange = (event) => {

        setSquarePaintedTwiceCheck(event.target.checked)
        // console.log("Bam" + squarePaintedTwiceCheck)
    }


    const handlePaintAmountChange = (event) => {
        setPaintAmount(event.target.value);
    };

    const handleSoundChange = (event) => {
        // Your logic for handling sound checkbox change
        setEnableSound(event.target.checked);
        // console.log(enableSound)
    };

    useEffect(() => {
        // console.log(enableSound)
    }, [enableSound, setEnableSound])

    return (
        <div className='Form-page'>
            <div className='form-container'>

                <h2>Experiment Form</h2>

                <h2>USER INPUTS</h2>

                <ColorPickers colors={colors} onColorChange={handleColorChange} addColor={addColor} />

                <FormControlLabel
                    control={<Checkbox name="oneSquarePaintedTwice" onChange={handleSquarePaintedTwiceChange} />}
                    label="One square painted twice"
                />

                {/* <Divider /> */}

                <form className='form-text'>

                    <MuiGrid item xs={12}>
                        <Typography gutterBottom>
                            Independent Variable:
                        </Typography>
                        <FormControlLabel
                            control={<Checkbox name="dimension" checked={dimensionCheck} onChange={handleDimensionChange} />}
                            label="Dimensions"
                        />
                        {/* <FormControlLabel
                                control={<Checkbox name="x-axis" checked={xAxisCheck} onChange={handleXAxisChange} />}
                                label="X Value"
                            /> */}
                        <FormControlLabel
                            control={<Checkbox name="repetitions" checked={repetitionsCheck} onChange={handleCheckboxChange} />}
                            label="Repetitions"
                        />

                    </MuiGrid>



                    {dimensionCheck &&
                        <MuiGrid item xs={12} sm={6}>
                            <TextField
                                label="Dimensions (comma separated list)"
                                variant="outlined"
                                fullWidth
                                name="Dimensions"
                                value={dimensionList}
                                onChange={handleDimensionList}

                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}

                        </MuiGrid>
                    }

                    {repetitionsCheck &&
                        <MuiGrid item xs={12} sm={6}>
                            <TextField
                                label="Repetitions (comma separated list)"
                                variant="outlined"
                                fullWidth
                                name="reps"
                                value={repetitionList}
                                onChange={handleRepetitionList}

                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}

                        </MuiGrid>
                    }

                    {repetitionsCheck &&
                        <Button onClick={() => handleErrorValidation(repetitionList, dimensionList)}>Check numbers</Button>
                    }

                    {dimensionCheck &&
                        <Button onClick={() => handleErrorValidation(repetitionList, dimensionList)}>Check numbers</Button>
                    }

                    {repetitionsCheck && validList &&
                        <>
                            <MuiGrid item xs={12} sm={6}>
                                <TextField
                                    label="Dimension Constant"
                                    variant="outlined"
                                    fullWidth
                                    name="dimConstant"
                                    value={dimensionConstant}
                                    onChange={handleDimensionConstant}

                                />
                                {error && <p style={{ color: 'red' }}>{error}</p>}

                            </MuiGrid>
                        </>
                    }

                    {dimensionCheck && validList &&
                        <>
                            <MuiGrid item xs={12} sm={6}>
                                <TextField
                                    label="Repetition Constant"
                                    variant="outlined"
                                    fullWidth
                                    name="repConstant"
                                    value={repetitionConstant}
                                    onChange={handleRepetitionConstant}

                                />

                            </MuiGrid>
                        </>
                    }

                    {validList && <Button variant="contained" type="submit" onClick={handleSubmit}>Submit</Button>}

                    {repetitionsCheck &&
                        <Dialog open={open} onClose={handleClose} fullScreen maxWidth="md" >
                            <Button onClick={handleClose}>------------ Close Experiment ------------</Button>
                            <Button onClick={handleStatDisplay}>------ Show table data -------</Button>
                            <div className='dialogue-container'>
                                {renderGrid && repetitionsCheck && repArray.map((repValue, index1) => {
                                    return <div className='repetition-container' key={index1}>
                                        <p>Grid Set {index1 + 1} </p>
                                        {
                                            Array.from({ length: repValue }).map((_, index2) => {

                                                // const newGridData = { /* ... */ }; // Collect any additional data needed for the grid
                                                // handleGridUpdate('Grid1', newGridData); // Use a unique name for each grid

                                                const tempGridName = `grid${index2}`
                                                const set = `set${index1}`
                                                // console.log(tempGridName)

                                                return <div className='grid-item' key={index2}>

                                                    <Grid rows={parseInt(dimensionConstant)} cols={parseInt(dimensionConstant)} colors={colors} speed={speed} enableSound={enableSound} squarePaintedTwiceCheck={squarePaintedTwiceCheck} gridName={tempGridName} set={set} handleGridUpdate={handleGridUpdate} format={"multi"} />
                                                </div>

                                            })
                                        }
                                    </div>


                                })}
                            </div>
                        </Dialog>
                    }

                    {dimensionCheck &&
                        <Dialog open={open} onClose={handleClose} fullScreen maxWidth="md">
                            <Button onClick={handleClose}>------------ Close Experiment ------------</Button>
                            <Button onClick={handleStatDisplay}>------ Show table data -------</Button>
                            <div className='dialogue-container'>
                                {renderGrid && dimensionCheck && dimArray.map((dimValue, index1) => {
                                    return <div className='repetition-container' key={index1}>
                                        <p>Grid Set {index1 + 1} </p>
                                        {
                                            Array.from({ length: repetitionConstant }).map((_, index2) => {

                                                // const newGridData = { /* ... */ }; // Collect any additional data needed for the grid
                                                // handleGridUpdate('Grid1', newGridData); // Use a unique name for each grid

                                                const tempGridName = `grid${index2}`
                                                const set = `set${index1}`

                                                // console.log(tempGridName)

                                                return <div className='grid-item' key={index2}>

                                                    <Grid rows={parseInt(dimValue)} cols={parseInt(dimValue)} colors={colors} speed={speed} enableSound={enableSound} squarePaintedTwiceCheck={squarePaintedTwiceCheck} gridName={tempGridName} set={set} handleGridUpdate={handleGridUpdate} format={"multi"} />
                                                </div>

                                            })
                                        }
                                    </div>


                                })}
                            </div>
                        </Dialog>
                    }

                    {setDisplayStats && 
                        <Dialog open={openStats} onClose={handleStatsClose} fullScreen maxWidth="md">
                            <SimulationResultsTable data={setStatistics} />
                            <Button onClick={() => console.log(setStatistics)}>show data</Button>
                        </Dialog>
                    }

                    {/* <Button onClick={() => console.log(masterGridData)}>Print Data</Button> */}

                    {/* <MuiGrid item xs={12} sm={6}>
                            <TextField
                                label="Y"
                                variant="outlined"
                                fullWidth
                                name="yDimension"
                                value={formData.yDimension}
                                onChange={handleChange}
                            />
                        </MuiGrid> */}






                    {/* <MuiGrid item xs={12}>
                            <Typography id="speed-slider" gutterBottom>
                                Speed Delay: {speed} ms
                            </Typography>
                            <Slider
                                value={speed}
                                onChange={(e) => setSpeed(e.target.value)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={1000}
                                aria-labelledby="speed-slider"
                            />
                        </MuiGrid> */}


                </form>

                {/* <FormControlLabel
                    control={<Checkbox name="enableSound" onChange={handleSoundChange} />}
                    label="Enable sound"
                /> */}
                {/* <p></p>     */}
                {/* {!renderGrid && <Button variant="contained" type="submit" onClick={handleSubmit}>Submit</Button>} */}

            </div>

            {/* {renderGrid && <Button onClick={handleReset}>Reset</Button>}

            {renderGrid && <Grid rows={parseInt(formData.yDimension)} cols={parseInt(formData.xDimension)} colors={colors} speed={speed} enableSound={enableSound} xAxisCheck={xAxisCheck} />} */}

        </div>
    );
};

export default SimulationForm;
